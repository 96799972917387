import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

export const RandomWord = () => {
  const [newWord, setNewWord] = useState<string[]>();
  const errorWord = 'local';
  const handleClick = () => {
    const url = 'https://jp5r4l839k.execute-api.us-east-1.amazonaws.com/random';

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setNewWord([...json.word]);
      } catch (error) {
        setNewWord(Array.from(errorWord));
        console.log('error', error);
      }
    };

    fetchData();
  };

  return (
    <Box sx={{ pt: 4, textAlign: 'center' }}>
      <Typography sx={{ pb: 2 }} variant="h2">
        Generate a 5 letter Wordle word
      </Typography>
      <Button sx={{ mt: 2 }} variant="contained" onClick={handleClick}>
        New word
      </Button>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 4,
          }}
        >
          {newWord &&
            newWord.map((char, index) => (
              <Box
                sx={{
                  display: 'flex',
                  m: '4px',
                  pt: '4px',
                  height: '62px',
                  width: '62px',
                  fontSize: '32px',
                  textTransform: 'uppercase',
                  border: '2px solid #3a3a3c',
                  justifyContent: 'center',
                }}
                component={'span'}
                key={index}
              >
                <span>{char}</span>
              </Box>
            ))}
        </Typography>
      </Box>
    </Box>
  );
};
