import {
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
  responsiveFontSizes,
} from '@mui/material';
import { RandomWord } from './molecules/random-word.molecules';

let theme = createTheme({
  palette: {
    background: {
      default: '#121213',
    },
    text: {
      primary: '#d7dadc',
    },
  },
  typography: {
    fontFamily: [
      '"Clear Sans"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),

    h1: {
      fontSize: '36px',
      fontWeight: 700,
      letterSpacing: '3.2px',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 500,
    },
  },
});
theme = responsiveFontSizes(theme);

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm" sx={{ height: '40px', border: '1px ' }}>
          <Typography
            variant="h1"
            sx={{
              py: 1,
              borderBottom: '1px solid #3a3a3c',
              textAlign: 'center',
            }}
          >
            Wordle generator
          </Typography>
          <RandomWord />
        </Container>
      </ThemeProvider>
    </>
  );
}

export default App;
